import $ from 'jquery'

document.addEventListener('DOMContentLoaded', function(event) {
    if ($('body.checkout-page')) {
        var $form = $('#ludri-checkout-form')

        $('#gift').click(function (event) {
            if(event.target.checked == true) {
                $('#block-gift').show('blind')
            } else {
                $('#block-gift').hide('blind')
            }
        })

        $('#isgiftmessage').click(function (event) {
            if(event.target.checked == true) {
                $('#block-gift-message').show('blind')
            } else {
                $('#block-gift-message').hide('blind')
            }
        })

        let checkoutLaunchModals = () => {
            var checkoutShippingAddressModal = document.getElementById('checkoutShippingAddressModal')
            if (checkoutShippingAddressModal) {
                checkoutShippingAddressModal.addEventListener('show.bs.modal', function (event) {
                    var button = event.relatedTarget
                    var actionUrl = button.getAttribute('data-action')

                    $.ajax({
                        type: 'GET',
                        url: actionUrl,
                        success: function(data) {
                            $('#checkoutShippingAddressModal .modal-content .modal-body').html(data)
                            $('#checkoutShippingAddressModal .modal-content .modal-body .submit-shippingaddress').on('click', function(event) {
                                $form.submit()
                            })
                        }
                    })
                })
            }

            var checkoutBillingAddressModal = document.getElementById('checkoutBillingAddressModal')
            if (checkoutBillingAddressModal) {
                checkoutBillingAddressModal.addEventListener('show.bs.modal', function (event) {
                    var button = event.relatedTarget
                    var actionUrl = button.getAttribute('data-action')

                    $.ajax({
                        type: 'GET',
                        url: actionUrl,
                        success: function(data) {
                            $('#checkoutBillingAddressModal .modal-content .modal-body').html(data)
                            $('#checkoutBillingAddressModal .modal-content .modal-body .submit-billingaddress').on('click', function(event) {
                                $form.submit()
                            })
                        }
                    })
                })
            }
        }

        $(document).on('updateCheckout', function(event, cart) {
            checkoutLaunchModals()
        });

        checkoutLaunchModals()

        function updateCheckout() {
            var url = $form.attr('action') + '?ajax=true'
            $.ajax({
                type: 'POST',
                url: url,
                data: $form.serialize(),
                success: function(data) {
                    $('#ludri-shipping-method-form').html(data.shipping_method_form)
                    $('#ludri-shipping-address').html(data.shipping_address)
                    $('#ludri-cart-resume').html(data.cart_resume)
                    $('#ludri-payment-method-form').html(data.payment_method_form)
                    $('#cart-resume-total').html(data.resume_total)
                    $(document).trigger('updateCheckout', {cart: data.cart});
                }
            })
        }
        $form.on('change', '#country', function() {
            updateCheckout()
        })
        console.log('load checkout')
        $form.on('change', 'input[type=radio][name=id_shippingmethod]', function(event) {
            $('.shippingmethod-select').each((i) => {
                console.log(i)
                let $elem = $(this)
                console.log($elem)
                console.log($elem.is(':checked'))
                if ($elem.is(':checked')) {
                    $elem.parent().parent().addClass('selected')
                } else {
                    $elem.parent().parent().removeClass('selected')
                }
            })
            updateCheckout()
        })
        $('#billing').click(function (e) {
            if(e.target.checked == true) {
                $('#billing-address').show('blind')
            } else {
                $('#billing-address').hide('blind')
            }
        })
    }
})