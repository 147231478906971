import $ from 'jquery';
import Splide from '@splidejs/splide'

if (document.querySelector('.splide')) {
    new Splide('.splide', {
        cover: true,
        height: '710px',
        breakpoints: {
            1024: {
                height: '375px',
            },
        }
    }).mount()
}

if (document.querySelector('.add-product-action')) {
    $('.add-product-action').submit(function(event) {
        event.preventDefault()
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize() + '&submit=submit',
            success: function(data, status) {
                $('.cart-data-qty').html(data.products_qty)
                if(data.status == 'ok') {
                    $('.cart-data-qty').removeClass('d-none')
                    alert('Produit ajouté')
                } else if(data.status == 'product unavailable') {
                    alert('Produit indisponible')
                }
            }
        })
    })
}

if (document.querySelector('.cart-data-qty')) {
    $.getJSON('/cart/json/', function(data) {
        $('.cart-data-qty').html(data.products_qty)
    })
}