import $ from 'jquery';

document.addEventListener('DOMContentLoaded', function(event) {
    $(document).on('updateCheckout', function(event, cart) {
        initRelaisColis();
    });
    function initRelaisColis() {
        if ($('.relais-colis-launch')) {
            var displayPopUpRCalready = false;

            var createIframeMap = function(callback) {
                var iframeDiv = document.createElement('iframe');

                iframeDiv.setAttribute('style', 'border:0; position: fixed; z-index:1000; left: calc(50% - 240px); top:60px ; background-color: white; animation: anim 1.3s ease-in-out; ');
                iframeDiv.setAttribute('width', '480');
                iframeDiv.setAttribute('height', '580');
                iframeDiv.setAttribute('id', 'relaisColisIframe');

                if (location.protocol == 'https:') {
                    iframeDiv.setAttribute('src', 'https://service.relaiscolis.com/widgetrc/');
                } else {
                    iframeDiv.setAttribute('src', 'http://service.relaiscolis.com/widgetrc/');
                }

                var overlay = document.createElement('div');
                overlay.setAttribute('id', 'overlay');
                overlay.setAttribute('style', 'position: fixed; top: 0;  left: 0;  width: 100%; height: 100%;  background: #000;  opacity: 0.5; filter: alpha(opacity=50);');

                //overlay.addEventListener('click', function () {
                //    $('#overlay').css({'display': 'none'});
                //    $('#relaisColisIframe').css({'display': 'none'});
                //}, false);

                callback(overlay, iframeDiv);
            }

            var displayPopUpRC = function() {
                if (!displayPopUpRCalready) {
                    displayPopUpRCalready = true
                    createIframeMap(function (overlay, iframeDiv) {
                        $('body').append(iframeDiv)
                        $('body').append(overlay)
                    });
                } else {
                    $('#overlay').css({'display': 'block'});
                    $('#relaisColisIframe').css({'display': 'block'});
                }
            }

            var launchRelaisColis = function (callback)
            {
                displayPopUpRC()
                receiveMessage = function(event) {
                    if((event.data).hasOwnProperty('id')  && (event.data).hasOwnProperty('name') ){
                        $('#overlay').css({'display': 'none'});
                        $('#relaisColisIframe').css({'display': 'none'});
                        callback(event.data);
                    }
                }
                window.addEventListener('message', receiveMessage, false);
            }

            var relaisColisCallback = function(data) {
                $('#gotopayment').prop('disabled', false);
                var html_address = '<p>Vous avez choisi le relais : </br>'
                    + '<span class="fw-500">'
                    + data.id + ' - ' + data.name
                    + '</br> Adresse : '
                    + data.location.formattedAddressLine + '<br/>'
                    + data.location.formattedCityLine + '<br/>'
                    + '</span>';
                    + '</p>';
                $('.relais-address-selected .relais-selected').html(html_address)
                $('.relais-colis-launch').html('Modifier le point relais')
                $('.relais-colis-id').val(data.id)
                $('.relais-colis-name').val(data.name)
                $('.relais-colis-address').val(data.location.formattedAddressLine)
                $('.relais-colis-zipcode').val(data.location.postalCode)
                $('.relais-colis-city').val(data.location.city)
            }

            $('.relais-colis-launch').on('click', function(event) {
                $('#gotopayment').prop('disabled', true)
                launchRelaisColis(relaisColisCallback)
                $('#relais-address-selected').show()
            })

            if ($('.checkout-shippingmethod-relaiscolis .checkout-shippingmethod-input input[type="radio"]').is(':checked')) {
                $('#gotopayment').prop('disabled', true)
                launchRelaisColis(relaisColisCallback)
                $('#relais-address-selected').show()
            }

        }
    }
    initRelaisColis();
})