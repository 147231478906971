import $ from 'jquery';

$(document).ready(function() {
    $('.btn-gdpr-accept').on('click', function() {
        $.ajax({
            url: '/gdpr-accept?accept=true',
            datatype: 'json'
        }).done(function(data) {
            console.log(data);
            if (data.success == 'ok') {
                $('.gdpr-cookies').hide();
            }
        });

    });
});