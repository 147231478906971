import $ from 'jquery'

$(document).ready(() => {
    $('.input-search').on('keyup', (event) => {
        if (13 === event.keyCode) {
            event.preventDefault()
            document.getElementById('form-search').click()
        }
    })

    $('.mobile-link-search').on('click', (event) => {
        $('.mobile-search').show()
        $('.mobile-search').addClass('show')
        $('.mobile-input-search').focus()

    })

    $('.mobile-link-search-close').on('click', (event) => {
        $('.mobile-search').removeClass('show')
        setTimeout(() => { $('.mobile-search').hide() }, 700)
    })

})