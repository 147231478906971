import $ from 'jquery'

document.addEventListener('DOMContentLoaded', function(event) {
    if ($('body.cart-page')) {
        console.log('cart js')

        sendResumeAdress = () => {
            let $form = $('#form-resume')
            console.log('#form-resume')
            console.log($form)
            $form.submit((event) => {
                event.preventDefault()
                $.ajax({
                    type: 'POST',
                    url: $form.attr('action'),
                    data: $form.serialize()
                }).done(function (data) {
                    if (data.status == 'success') {
                        document.location.href = data.redirect
                    } else {
                        $('#cartResumeModal .modal-content .modal-body').html(data.html)
                    }
                    console.log(data)
                })
            })
        }

        var cartResumeModal = document.getElementById('cartResumeModal')
        if (cartResumeModal) {
            cartResumeModal.addEventListener('show.bs.modal', (event) => {
                var button = event.relatedTarget
                var actionUrl = button.getAttribute('data-action')

                $.ajax({
                    type: 'GET',
                    url: actionUrl,
                    success: function(data) {
                        $('#cartResumeModal .modal-content .modal-body').html(data.html)
                        sendResumeAdress()
                    }
                })
            })
        }
    }
})