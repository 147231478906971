import $ from 'jquery'

$(document).ready(function() {
    var elementPosition = $('#header').offset()
    //console.log('#header : ' + elementPosition.top + 'px')
    $(window).scroll(function() {
        //console.log($(window).scrollTop())
        if ($(window).scrollTop() > elementPosition.top) {
            $('#header').addClass('fixed')
        } else {
            $('#header').removeClass('fixed')
        }
    })
})