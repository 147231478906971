import $ from 'jquery';

console.log('load list catalog')

document.addEventListener('DOMContentLoaded', (event) => {
    if ($('body.catalog-page')) {
        $('#catalog-order').on('change', (event) => {
            console.log(event)
            $input = $(event.target)
            $('#catalog-search #order').val($input.val())
            $('#catalog-search').submit()
        })
        $('#catalog-perpage').on('change', (event) => {
            $input = $(event.target)
            $('#catalog-search #perpage').val($input.val())
            $('#catalog-search').submit()
        })
    }
})